import React from 'react'
import { useRecordContext } from 'react-admin';
import { List, Datagrid, TextField, EmailField,
  EditButton, DeleteButton,BooleanField,
  Edit, TextInput, BooleanInput,PasswordInput,
  Create, SimpleForm, DateInput, NumberInput, required
} from 'react-admin'

const CustomBulkActionButtons = (props) => (
  <React.Fragment>
    {/* Add any other custom bulk actions here if needed */}
  </React.Fragment>
);
const validatePort = (value) => {
  if (value < 0 || value > 65535) {
    return 'Port must be between 0 and 65535';
  }
  return undefined;
};

const MaskedField = ({ source, label }) => {
  const record = useRecordContext(); // Get the record from the context
  const value = record ? record[source] : '';
  const maskedValue = value ? '****' : '';  // Mask the value
  return <span>{maskedValue}</span>;
};

const validateRequired = required('This field is required');
export const ProviderList = () => {
  return <List pagination={false}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons />}>
      <TextField sortable={false} disabled source="id"/>
      <TextField sortable={false} source="description"/>
      <TextField sortable={false} source="hostName"/>
      <BooleanField sortable={false} source="isSecure"/>
      <TextField sortable={false} source="port"/>
      <MaskedField source="userName" label="User Name" />
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

export const ProviderEdit= () => {
  return (
    <Edit title='Edit SMPP Provider' >
      <SimpleForm>
        <TextInput disabled source="id"/>
        <TextInput source="description"/>
        <TextInput source="hostName" validate={validateRequired} label="Host Name"/>
        <BooleanInput source="isSecure"/>
        <NumberInput source="port" validate={[required(), validatePort]}  label="Port"/>
        <TextInput source="userName" type="password"  validate={validateRequired}  label="User Name"/>
        <PasswordInput source="userPassword" validate={validateRequired} />
      </SimpleForm>
    </Edit>
  )
}

export const ProviderCreate = () => {
  return (
    <Create title='Create Provider'>
      <SimpleForm>
        <TextInput source="description"  />
        <TextInput source="hostName" validate={validateRequired} />
        <BooleanInput source="isSecure"  />
        <NumberInput source="port" validate={[required(), validatePort]}   />
        <TextInput source="userName" label="User Name" validate={validateRequired} />
        <TextInput source="userPassword" label="User Password" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};