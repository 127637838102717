import * as React from "react";
import { Card, CardContent, CardHeader } from '@mui/material';
import { Title } from 'react-admin';
export default () => (
  <Card >
    <Title title="Welcome to the administration panel" />
    <CardHeader title="Welcome to the administration panel" />
    <CardContent>Here you can configure users, DID, MX system setting, providers.</CardContent>
  </Card>
);
