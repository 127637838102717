import React from 'react'
import { List, BooleanField, Datagrid, TextField, ReferenceField,DateField, EditButton,NumberField,
  DeleteButton, Edit, SimpleForm,ReferenceInput,SelectArrayInput,ReferenceManyField,ChipField,SingleFieldList,
  ReferenceArrayInput, SelectInput,required, NumberInput,ExportButton,CreateButton, TopToolbar,
  TextInput, BooleanInput, Create, useListContext  } from 'react-admin'


// Custom validators
const validateDomain = (value) => {
  if (!value.trim() || !/\.com$/.test(value)) {
    return 'Domain must be valid and end with .com';
  }
  return undefined;
};

const ListActions = (props) => {
  const { total } = useListContext();

  return (
    <TopToolbar>
      <CreateButton basePath={props.basePath} />
      {total > 0 && <ExportButton />}
      {/* Add more custom actions here if necessary */}
    </TopToolbar>
  );
};

const validatePort = (value) => {
  if (value < 0 || value > 65535) {
    return 'Port must be between 0 and 65535';
  }
  return undefined;
};

const validateLatinLetters = (value) => {
  if (!/^[A-Za-z]+$/.test(value)) {
    return 'Only Latin letters are allowed';
  }
  return undefined;
};

const CustomBulkActionButtons = (props) => (
  <React.Fragment>
    {/* Add any other custom bulk actions here if needed */}
  </React.Fragment>
);


export const MMSProviderList = () => {
  return (
    <List title="List of MMS Providers" pagination={false} actions={<ListActions />} >
       <Datagrid bulkActionButtons={<CustomBulkActionButtons />}>
        <TextField source="id" sortable={false} />
        <TextField source="hostName" sortable={false}/>
        <TextField source="port" sortable={false}/>
        <TextField source="smsgateDns" sortable={false}/>
        <TextField source="description" sortable={false}/>
        {/* Other fields as needed */}
        <EditButton />
        <DeleteButton></DeleteButton>
      </Datagrid>
    </List>
  );
};

export const MMSProviderEdit = () => {
  return (
    <Edit title='Edit MMS Provider'>
      <SimpleForm>
        <TextInput disabled source='id'/>
        <TextInput validate={[required(), validateDomain]} source="hostName" label="Hostname"/>
        <TextInput validate={validateLatinLetters} source="description" label="Description"/>
        <NumberInput validate={[required(), validatePort]} source="port" label="Port"/>
        <TextInput validate={[required(), validateDomain]} source="smsgateDns" label="SMS Gate DNS"/>
        <BooleanInput source="isSecure" label="Is Secure"/>
      </SimpleForm>
    </Edit>
  );
};

export const MMSProviderCreate = () => {
  return (
    <Create title='Create MMS Provider'>
      <SimpleForm>
        <TextInput validate={[required(), validateDomain]} source="hostName" label="Hostname" />
        <NumberInput validate={[required(), validatePort]} source="port" label="Port" />
        <BooleanInput source="isSecure" label="Is Secure" />
        <TextInput validate={[required(), validateDomain]} source="smsgateDns" label="SMSgate DNS" />
        <TextInput validate={validateLatinLetters} source="description" label="Description" />
      </SimpleForm>
    </Create>
  );
};

