import * as React from 'react';
import { useState } from 'react';
import logo from './assets/ic_logo_white.png' // relative path to image
import PropTypes from 'prop-types';
//import { useLogin, useNotify, Notification } from 'react-admin';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';

const MyLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(username, password)
    login({ username, password }).catch(function(error){
      // notify('Invalid username or password')
      console.log(error)
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        {
          type: 'warning',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error
                : undefined,
          },
        }
    )})
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background:
            `radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: '#25a55b' ,width: 65, height: 65}}>
              <img src={logo} sx={{width: 65, height: 65}} />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          >
            Zultys SMS Admin UI
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="username"
                onChange={e => setUsername(e.target.value)}
                value={username}
                label={translate('ra.auth.username')}
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: '0.1em' }}>
              <TextInput
                source="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress size={25} thickness={2} />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};
//
// Login.propTypes = {
//   authProvider: PropTypes.func,
//   previousRoute: PropTypes.string,
// };

export default MyLoginPage;

// interface FormValues {
//   username?: string;
//   password?: string;
// }
