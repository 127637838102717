import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { useFormContext } from 'react-hook-form';


const apiUrl = '/sms'; 
const httpClient = fetchUtils.fetchJson;
const { HttpError } = fetchUtils;

// const httpClient = (url, options = {}) => {

export default {
  getList: (resource, params) => {
    console.log(resource, params)
    if (resource === 'users') {
      return httpClient(`${apiUrl}/users`).then(({headers, json}) => ({
          data: json.data,
          total: json.total
        })
      );
    }
    
      if (resource === 'dids') {
        return httpClient(`${apiUrl}/dids`).then((
          { headers, json }) =>({
          data: json.data,
          total: json.total
          })

        );
    }

      else if (resource === 'providers') {
        return httpClient(`${apiUrl}/providers`).then((
          { headers, json }) =>({
          data: json.data,
          total: json.total
          })

        );
      }
      else if (resource === 'mmsproviders') {
        return httpClient(`${apiUrl}/mmsproviders`).then(({ json }) => {
          // Assuming your API doesn't provide a total directly,
          // you can default to the length of the data array for pagination.
          // If your API includes a total count elsewhere in the response, use that instead.
          const total = json.data.length; // Defaulting to the length of the data array
          return {
            data: json.data,
            total: total
          };
        });
      }
      else if (resource === 'mmsipwhitelist') {
        return httpClient(`${apiUrl}/mmsipwhitelist`).then(({ json }) => {
          // Assuming your API doesn't provide a total directly,
          // you can default to the length of the data array for pagination.
          // If your API includes a total count elsewhere in the response, use that instead.
          const total = json.data.length; // Defaulting to the length of the data array
          return {
            data: json.data,
            total: total
          };
        });
      }

    if(resource === 'systems'){

      return httpClient(`${apiUrl}/systems?filter={"serial":${encodeURIComponent(JSON.stringify(params.filter.serial))}}`).then((
        { headers, json }) =>({
        data: json.data,
        total: json.total
        })

      );
    }
  },

  getOne: (resource, params) => {
    if(resource==='dids') {
      return  httpClient(`${apiUrl}/dids/${params.id}`).then(({json}) => ({
        data: json,
      }))
    }
      if(resource === 'users'){
      return  httpClient(`${apiUrl}/users/${params.id}`).then(({ json }) => ({
          data: json,
        }))
      }
    if(resource === 'providers'){
      return  httpClient(`${apiUrl}/providers/${params.id}`).then(({ json }) => ({
        data: json,
      }))
    }
    if(resource === 'mmsproviders'){
      return  httpClient(`${apiUrl}/mmsproviders/${params.id}`).then(({ json }) => ({
        data: json,
      }))
    }
    if(resource === 'mmsipwhitelist'){
      return  httpClient(`${apiUrl}/mmsipwhitelist/${params.id}`).then(({ json }) => ({
        data: json,
      }))
    }
    if(resource === 'systems'){
      return   httpClient(`${apiUrl}/systems/${params.id}`).then(({ json }) => ({
        data: json,
      }))
    }
} ,
  getMany: (resource, params) => {
    // console.log(resource, params)
    // const query = {
    //   filter: JSON.stringify({ ids: params.ids }),
    // };
    // const url = `${apiUrl}/${resource}?${stringify(query)}`;
    // return httpClient(url).then(({ json }) => ({ data: json }));
    console.log(resource, params)

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };




    if(resource === 'providers'){
      // return httpClient(`${apiUrl}/providers`).then(function (data) {
      //   console.log(data.json.data,data.json.total)
      //   return   {
      //     data: data.json.data,
      //     total: data.json.total
      //   }
      // })
      console.log(query.filter.id)
      // const url = `${apiUrl}/providers?filter={"ids":[6, 0]}`;
      // return httpClient(url).then(({ json }) => ({ data: [json] }));

      return httpClient(`${apiUrl}/providers?filter={"ids":${JSON.stringify(params.ids)}}`).then(function (data) {
        console.log(data.json.data,data.json.total)
        if(data.json.data === null) {
          return   {
            data: [],
          }
        } else {
          return   {
            data: data.json.data,
          }
        }
      })
      // console.log(url);
    }
    if(resource === 'systems'){
      console.log(query)
      
      // const url = `${apiUrl}/systems?filter={"ids":[6, 0]}`;
      // return httpClient(url).then(({ json }) => ({ data: [json] }));

      return httpClient(`${apiUrl}/systems?filter={"ids":${JSON.stringify(params.ids)}}`).then(function (data) {
        console.log(data.json.data,data.json.total)
        if(data.json.data === null) {
          return   {
            data: [],
          }
        } else {
          return   {
            data: data.json.data,
          }
        }

      })
      // return httpClient(`${apiUrl}/systems`).then(function (data) {
      //   console.log(data)
      //   return   {
      //     data: data.json.data,
      //     total: data.json.total
      //   }
      // })

    }


  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  create: (resource, params)  => {
    if (resource === 'users') {
     return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_user'
        })
        ,
      }).then(({json}) => console.log(resource, json, params.data) || ({
        data: {...params.data, id: json.data.id},
      }))
    }
    if (resource === 'providers') {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_provider'
        })
        ,
      }).then(({json}) => console.log(resource, json, params.data) || ({
        data: {...params.data, id: json.data.id},
      }))
    }
    if (resource === 'mmsproviders') {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_mms_provider'
        }),
      })
      .then(({ json }) => {
        // Check if the response indicates a failure
        if (json.success === false) {
          throw new Error(json.error.msg || "Unknown error occurred");
        }
    
        console.log(resource, json, params.data);
        return {
          data: {...params.data, id: json.data.id},
        };
      })
    }
    if (resource === 'dids') {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_did'
        })
        ,
      }).then(({json}) => console.log(resource, json, params.data) || ({
        data: {...params.data, id: json.data.id},
      }))
    }
    if (resource === 'mmsipwhitelist') {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_mms_ip_whitelist'
        })
        ,
      }).then(({json}) => console.log(resource, json, params.data) || ({
        data: {...params.data, id: json.data.id},
      }))
    }

    if (resource === 'systems') {
      console.log(params.data)
      if(params.data.status){
        params.data.status = 'enabled'
      } else {
        params.data.status = 'disabled'
      }
      if(params.data.providerId === ""){
        params.data.providerId = 0;
      }
      return httpClient(`${apiUrl}/systems`, {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
        body: new URLSearchParams({
          "data": JSON.stringify(params.data),
          'command': 'add_system'
        })
      }).then(function (data) {
        console.log(data, data.json)
        return   {
          data: data.json.data,
        }
      })
    }

  },
  update: (resource, params) => {
    console.log(`${apiUrl}/${resource}/${params.id}`)

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: new URLSearchParams({
        "data": JSON.stringify(params.data),
        // 'command': 'add_user'
      }),
      headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
    }).then(({ json }) => ({ data: json.data }))
  },


  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    if(resource==='systems')
    return httpClient(`${apiUrl}/systems/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
    if(resource==='users'){
      return httpClient(`${apiUrl}/users/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }))
    }
    if(resource==='dids'){
      return httpClient(`${apiUrl}/dids/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }))
    }
    if(resource==='providers'){
      return httpClient(`${apiUrl}/providers/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }))
    }
    if(resource==='mmsproviders'){
      console.log(`${apiUrl}/mmsproviders/${params.id}`)
      return httpClient(`${apiUrl}/mmsproviders/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }))
    }
    if(resource==='mmsipwhitelist'){
      return httpClient(`${apiUrl}/mmsipwhitelist/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }))
    }
  },


  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  generateSecret:(resource, params) => {
    return httpClient(`${apiUrl}/generate_secret`, {
      method: 'GET',
      // headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
      // body: new URLSearchParams({
      //   'command': 'generate_secret'
      // })
    }).then(({ json }) => ({ data: json }));
  },
  changePassword: (password, repeatPass, id) => {
    console.log(password, repeatPass, id);
    let data = {
      newPassword: password,
      repeatedNewPassword: repeatPass
    };
  
    return httpClient(`${apiUrl}/users/change_password/${id}`, {
      method: 'POST',
      headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }),
      body: new URLSearchParams({
        "data": JSON.stringify(data),
      })
    })
    .then(response => {
      console.log(response)
      if (data.status < 200 || data.status >= 300) {
        console.log(data)
        throw new Error(data);
      }
      console.log(data)
      return data
    })
    .catch(error => {
      console.log(error,error.body)
      console.log(error.body.error.msg)
      if (error.body) {
        // This will handle the HttpError and provide the correct error message
        console.error('Caught error:', error.body.error.msg);
        throw new Error(error.body.error.msg);
      }
      console.error('Caught error:', error);
      throw error;
    });
  },
  generateToken:(resource, params) => {
    return httpClient(`${apiUrl}/generate_access_token`, {
      method: 'GET',
      // headers: new Headers({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}),
      // body: new URLSearchParams({
      //   'command': 'generate_access_token'
      // })
    }).then(({ json }) => ({ data: json }));
  },

};



