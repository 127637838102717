// in src/authProvider.js
const apiUrl = '';
export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    console.log(username,password)
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const request = new Request(`${apiUrl}/sms/auth`, {
      method: 'POST',
      body: new URLSearchParams({
        'username': username,
        'password': password
        // 'command': 'auth'
      }),
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(auth => {
        console.log(auth)
        if(auth.err){
          console.log(auth.err)
          throw new Error(auth.err);
        }
        console.log(auth)
        localStorage.setItem('token', JSON.stringify(auth.session));
      })
      .catch((e) => {
        throw new Error(e)
      });
  },
  // getIdentity: () => {
  //   try {
  //     const { id, fullName, avatar } = JSON.parse(localStorage.getItem('token'));
  //     return Promise.resolve({ id, fullName, avatar });
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    console.log('check auth')
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
